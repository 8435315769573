import { FunctionComponent, useCallback, useEffect, useState } from "react";

import { useIntl } from "react-intl";
import { UAParser } from "ua-parser-js";
import cx from "classnames";

import {
  CollectionStatus,
  CollectionStatusResponse,
  InsuranceCompaniesStatusResponse,
  InsuranceCompany,
  LoginMethod,
  StartCollectionRequest,
  completedStatuses,
  dataLifePensionMove,
  failedStatuses,
  pendingStatuses,
} from "../../../../../data/dataLifePensionMove";
import { useHistory, useLocation } from "react-router-dom";
import { useUser } from "../../../../../context/UserContext";
import {
  Button,
  CircleButton,
  InputErrorList,
  NewIcon,
  Spinner,
  Typography,
  openBankidApp,
  Snackbar,
  SNACKBAR_TYPES,
} from "@lysaab/ui-2";
import { TranslatedText } from "../../../../../components/TranslatedText";
import { PensionLogo } from "../../../../../components/pensionLogo/PensionLogo";
import { QRCodeCanvas } from "qrcode.react";

import "./InsuranceSigningList.scss";

interface Props {
  next: () => void;
  reset: () => void;
}

const useSelectedCompaniesConfig = () => {
  const [companies, setCompanies] = useState<
    Array<InsuranceCompaniesStatusResponse> | undefined
  >(undefined);

  useEffect(() => {
    dataLifePensionMove.getInsuranceCompanies().then(setCompanies);
  }, []);

  return companies;
};

type CompanyInfo = InsuranceCompaniesStatusResponse & CollectionStatusResponse;

type CompanyInfoStateType =
  | { stage: "LOADING" }
  | { stage: "ERROR" }
  | {
      stage: "WAITING";
      companies: Array<CompanyInfo>;
    }
  | {
      stage: "POLLING";
      companies: Array<CompanyInfo>;
    };

type StartBankId = (
  insuranceCompany: InsuranceCompany,
  loginMethod: LoginMethod
) => void;

const useCompaniesInfo = (): CompanyInfoStateType & {
  startBankId?: StartBankId;
  isAllFinished: boolean;
} => {
  const [currentState, setCurrentState] = useState<CompanyInfoStateType>({
    stage: "LOADING",
  });
  const user = useUser();
  const selectedCompaniesConfig = useSelectedCompaniesConfig();

  const poll = useCallback(
    (companies: InsuranceCompany[]) => {
      let isStale = false;

      if (!selectedCompaniesConfig) {
        return;
      }

      const pollPromises = companies.map((insuranceCompany) => {
        return dataLifePensionMove.collectionStatus(insuranceCompany);
      });

      Promise.allSettled(pollPromises).then((responses) => {
        if (isStale) {
          return;
        }

        const successfulResponses = responses
          .filter(
            (
              response
            ): response is PromiseFulfilledResult<CollectionStatusResponse> => {
              return response.status === "fulfilled";
            }
          )
          .map((res) => res.value);

        setCurrentState((oldState) => {
          let newCompanies: Array<CompanyInfo>;

          if (oldState.stage === "WAITING" || oldState.stage === "POLLING") {
            newCompanies = oldState.companies.map((company) => {
              const { loginMethods, insuranceCompanyDisplayName } = company;

              const successfulCompany = successfulResponses.find((response) => {
                return response.insuranceCompany === company.insuranceCompany;
              });
              const failedCompany =
                !successfulCompany &&
                companies.includes(company.insuranceCompany) &&
                responses.length !== 0;

              if (successfulCompany) {
                return {
                  ...successfulCompany,
                  loginMethods,
                  insuranceCompanyDisplayName,
                };
              }
              if (failedCompany) {
                return {
                  ...company,
                  status: "FAILED",
                };
              }
              return company;
            });
          } else if (oldState.stage === "LOADING") {
            newCompanies = selectedCompaniesConfig.map((company) => {
              const successfulCompany = successfulResponses.find((response) => {
                return response.insuranceCompany === company.insuranceCompany;
              });

              // In case we have a successful response we override the properties from the orignal config with the received content.
              if (successfulCompany) {
                return {
                  ...company,
                  ...successfulCompany,
                };
              }

              // In case we don't have a successful response we manually set status to "CREATED"
              // This ensure user is presented with buttons to start bankId in case the company is the first company in the list.
              // The buttons is in turn needed to set the collection method in the URL which allow user to retry the operation.
              return {
                ...company,
                status: "CREATED",
              };
            });
          } else {
            newCompanies = selectedCompaniesConfig.map((company) => {
              const successfulCompany = successfulResponses.find((response) => {
                return response.insuranceCompany === company.insuranceCompany;
              });

              if (successfulCompany) {
                return {
                  ...company,
                  ...successfulCompany,
                };
              }

              return {
                ...company,
                status: "FAILED",
              };
            });
          }

          return {
            stage: "WAITING",
            companies: newCompanies,
          };
        });
      });

      return () => {
        isStale = true;
      };
    },
    [selectedCompaniesConfig]
  );

  useEffect(() => {
    if (!selectedCompaniesConfig) {
      return;
    }

    let abort: undefined | (() => void);
    let timeoutId: NodeJS.Timeout;

    if (currentState.stage === "LOADING") {
      abort = poll(
        selectedCompaniesConfig.map((company) => {
          return company.insuranceCompany;
        })
      );
    } else if (currentState.stage === "WAITING") {
      timeoutId = setTimeout(() => {
        setCurrentState((oldState) => {
          if (oldState.stage === "WAITING") {
            return {
              ...oldState,
              stage: "POLLING",
            };
          }
          return oldState;
        });
      }, 1000);
    } else if (currentState.stage === "POLLING") {
      const pendingCompanies = currentState.companies
        .filter((company) => pendingStatuses.includes(company.status))
        .map((company) => company.insuranceCompany);
      abort = poll(pendingCompanies);
    }

    return () => {
      abort?.();
      clearTimeout(timeoutId);
    };
  }, [poll, selectedCompaniesConfig, currentState]);

  const startBankId = useCallback(
    (insuranceCompany: InsuranceCompany, loginMethod: LoginMethod) => {
      if (!user.name) {
        return;
      }

      const body: StartCollectionRequest = {
        name: user.name,
        insuranceCompany: insuranceCompany,
        loginMethod: loginMethod,
      };

      dataLifePensionMove
        .startCollection(body)
        .then((response) => {
          poll([insuranceCompany]);
        })
        .catch((error) => {
          console.log(`Not able to start collection. Error: ${error}`);
        });
    },
    [user.name, poll]
  );

  let isAllFinished = false;

  if (currentState.stage === "WAITING" || currentState.stage === "POLLING") {
    isAllFinished = currentState.companies.every((company) => {
      return (
        completedStatuses.includes(company.status) ||
        failedStatuses.includes(company.status)
      );
    });
  }

  return {
    ...currentState,
    isAllFinished,
    startBankId:
      currentState.stage === "WAITING" || currentState.stage === "POLLING"
        ? startBankId
        : undefined,
  };
};

const StatusMessage = ({ status }: { status: CollectionStatus }) => {
  if (["CREATED", "RUNNING", "LOGIN"].includes(status)) {
    return (
      <TranslatedText id="sweden.transfer-pension.insurance-signing-list.idle" />
    );
  }
  if (
    [
      "LOGIN_TIMEOUT",
      "AUTHENTICATION_CONFLICT",
      "AUTHENTICATION_MISMATCH",
    ].includes(status)
  ) {
    return (
      <TranslatedText id="sweden.transfer-pension.insurance-signing-list.auth-error" />
    );
  }
  if (["EXTRA_INFO_NEEDED"].includes(status)) {
    return (
      <TranslatedText id="sweden.transfer-pension.insurance-signing-list.extra-information" />
    );
  }
  if (["MANUAL_LOGIN_NEEDED"].includes(status)) {
    return (
      <TranslatedText id="sweden.transfer-pension.insurance-signing-list.manual-login-needed" />
    );
  }
  if (["INSTITUTE_DOWN"].includes(status)) {
    return (
      <TranslatedText id="sweden.transfer-pension.insurance-signing-list.institute-down" />
    );
  }
  if (pendingStatuses.includes(status)) {
    return (
      <TranslatedText id="sweden.transfer-pension.insurance-signing-list.pending" />
    );
  }
  if (completedStatuses.includes(status)) {
    return (
      <TranslatedText id="sweden.transfer-pension.insurance-signing-list.completed" />
    );
  }
  if (failedStatuses.includes(status)) {
    return (
      <TranslatedText
        id={"sweden.transfer-pension.insurance-signing-list.failed"}
      />
    );
  }

  return <>{status}</>;
};

export const InsuranceSigningList: FunctionComponent<Props> = ({
  next,
  reset,
}) => {
  const companiesInfo = useCompaniesInfo();
  const [shouldShowRequiredError, setShouldShowRequiredError] = useState(false);
  const query = new URLSearchParams(useLocation().search);
  const method = query.get("method");
  const intl = useIntl();

  if (companiesInfo.stage === "LOADING") {
    return <Spinner />;
  }
  if (companiesInfo.stage === "ERROR") {
    return (
      <>
        <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
          <TranslatedText id="sweden.transfer-pension.insurance-signing-list.error" />
        </Snackbar>
        <Button
          block
          label={
            <TranslatedText id="sweden.transfer-pension.insurance-signing-list.retry" />
          }
          onClick={reset}
        />
      </>
    );
  }

  const bankIdActive = companiesInfo.companies.find(
    (company) => company.status === "RUNNING" || company.status === "LOGIN"
  );

  const firstCreated = companiesInfo.companies.find(
    (company) => company.status === "CREATED"
  );

  return (
    <section className="transfer-pension-insurance-signing-list">
      <Typography type="h1">
        <TranslatedText id="sweden.transfer-pension.insurance-signing-list.header" />
      </Typography>
      <Typography type="body">
        <TranslatedText id="sweden.transfer-pension.insurance-signing-list.ingress" />
      </Typography>
      <ul className="list">
        {companiesInfo.companies.map((company) => {
          return (
            <li key={company.insuranceCompany} className="list-item">
              <div
                className={cx({
                  glow: [
                    "COLLECTING",
                    "DATA_AVAILABLE",
                    "CHECKING_DATA",
                  ].includes(company.status),
                })}
              ></div>
              <span className="content">
                <section className="list-item-header">
                  <div className="list-item-header-left">
                    <PensionLogo
                      className="list-item-header-left__pension-logo"
                      pension={{ key: company.insuranceCompany }}
                      size={42}
                    />
                    <div>
                      <Typography type="label-large">
                        {company.insuranceCompanyDisplayName}
                      </Typography>
                      <Typography type="body">
                        <StatusMessage status={company.status} />
                      </Typography>
                    </div>
                  </div>
                  <div>
                    {completedStatuses.includes(company.status) && (
                      <NewIcon.Check />
                    )}
                    {failedStatuses.includes(company.status) &&
                      !bankIdActive &&
                      companiesInfo.startBankId && (
                        <RetryButton
                          company={company}
                          startBankId={companiesInfo.startBankId}
                        />
                      )}
                  </div>
                </section>
                {(company.status === "RUNNING" ||
                  company.status === "LOGIN") && (
                  <section>
                    <CollectPensionSigningStatus
                      qrCode={company?.extraInformation?.qrCode}
                      animatedQrData={company?.extraInformation?.animatedQrData}
                      autostartToken={company?.extraInformation?.autostartToken}
                      status={company.status}
                    />
                  </section>
                )}

                {company.insuranceCompany === firstCreated?.insuranceCompany &&
                  !bankIdActive &&
                  companiesInfo.startBankId &&
                  !method && (
                    <StartBankIdButtons
                      company={company}
                      startBankId={companiesInfo.startBankId}
                    />
                  )}
                {company.insuranceCompany === firstCreated?.insuranceCompany &&
                  !bankIdActive &&
                  companiesInfo.startBankId &&
                  method && (
                    <BankIdAnyButton
                      company={company}
                      startBankId={companiesInfo.startBankId}
                    />
                  )}
              </span>
            </li>
          );
        })}
      </ul>
      <div className="next">
        {shouldShowRequiredError && !companiesInfo.isAllFinished ? (
          <div className="error-container">
            <InputErrorList
              errorMessages={[
                intl.formatMessage({
                  id: "sweden.transfer-pension.insurance-signing.not-complete-error",
                }),
              ]}
            />
          </div>
        ) : null}
        <Button
          type="button"
          variant="primary"
          label={intl.formatMessage({
            id: "sweden.transfer-pension.insurance-signing.next.button",
          })}
          block
          onClick={() => {
            if (!companiesInfo.isAllFinished) {
              setShouldShowRequiredError(true);
              return;
            }

            next();
          }}
        />
      </div>
    </section>
  );
};

const StartBankIdButtons = ({
  company,
  startBankId,
}: {
  company: CompanyInfo;
  startBankId: StartBankId;
}) => {
  const parser = new UAParser();
  const isMobile = parser.getDevice().type === "mobile";

  let primary: "SAME" | "OTHER" | undefined;

  const canOpenOnOtherDevice =
    company.loginMethods.includes(
      LoginMethod.SWEDISH_MOBILE_BANKID_OTHER_DEVICE
    ) ||
    company.loginMethods.includes(LoginMethod.SWEDISH_MOBILE_BANKID_ANY_DEVICE);

  const canOpenOnSameDevice =
    company.loginMethods.includes(
      LoginMethod.SWEDISH_MOBILE_BANKID_SAME_DEVICE
    ) ||
    company.loginMethods.includes(LoginMethod.SWEDISH_MOBILE_BANKID_ANY_DEVICE);

  if (isMobile) {
    if (canOpenOnSameDevice) {
      primary = "SAME";
    } else if (canOpenOnOtherDevice) {
      primary = "OTHER";
    } else {
      primary = undefined;
    }
  } else {
    if (canOpenOnOtherDevice) {
      primary = "OTHER";
    } else if (canOpenOnSameDevice) {
      primary = "SAME";
    } else {
      primary = undefined;
    }
  }

  if (primary === "SAME") {
    return (
      <div className="actions">
        <BankIdSameButton
          variant="primary"
          startBankId={startBankId}
          company={company}
        />
        {canOpenOnOtherDevice && (
          <BankIdOtherButton
            variant="outlined"
            startBankId={startBankId}
            company={company}
          />
        )}
      </div>
    );
  }

  if (primary === "OTHER") {
    return (
      <div className="actions">
        <BankIdOtherButton
          variant="primary"
          startBankId={startBankId}
          company={company}
        />
        {canOpenOnSameDevice && (
          <BankIdSameButton
            variant="outlined"
            startBankId={startBankId}
            company={company}
          />
        )}
      </div>
    );
  }

  return null;
};

const BankIdSameButton = ({
  company,
  startBankId,
  variant,
}: {
  company: CompanyInfo;
  startBankId: StartBankId;
  variant: "outlined" | "primary";
}) => {
  const intl = useIntl();
  const history = useHistory();

  /** Keep loginMethod in url to be able to render right version of BankID screen on refresh  */
  const addToUrl = (str: "SAME") => {
    const currentPath = window.location.pathname;
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set("method", str);
    history.replace(`${currentPath}?${searchParams.toString()}`);
  };

  return (
    <Button
      icon={variant === "primary" ? "BankId" : undefined}
      block
      type="button"
      variant={variant}
      label={intl.formatMessage({
        id: "sweden.transfer-pension.insurance-signing.same",
      })}
      onClick={() => {
        addToUrl("SAME");
        if (
          company.loginMethods.includes(
            LoginMethod.SWEDISH_MOBILE_BANKID_SAME_DEVICE
          )
        ) {
          startBankId(
            company.insuranceCompany,
            LoginMethod.SWEDISH_MOBILE_BANKID_SAME_DEVICE
          );
        } else if (
          company.loginMethods.includes(
            LoginMethod.SWEDISH_MOBILE_BANKID_ANY_DEVICE
          )
        ) {
          startBankId(
            company.insuranceCompany,
            LoginMethod.SWEDISH_MOBILE_BANKID_ANY_DEVICE
          );
        }
      }}
    />
  );
};

const BankIdOtherButton = ({
  company,
  startBankId,
  variant,
}: {
  company: CompanyInfo;
  startBankId: StartBankId;
  variant: "outlined" | "primary";
}) => {
  const intl = useIntl();
  const history = useHistory();

  /** Keep loginMethod in url to be able to render right version of BankID screen on refresh  */
  const addToUrl = (str: "OTHER") => {
    const currentPath = window.location.pathname;
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set("method", str);
    history.replace(`${currentPath}?${searchParams.toString()}`);
  };

  return (
    <Button
      icon={variant === "primary" ? "BankId" : undefined}
      block
      type="button"
      variant={variant}
      label={intl.formatMessage({
        id: "transfer-pension.insurance-signing-list.signing.button",
      })}
      onClick={() => {
        addToUrl("OTHER");
        if (
          company.loginMethods.includes(
            LoginMethod.SWEDISH_MOBILE_BANKID_OTHER_DEVICE
          )
        ) {
          startBankId(
            company.insuranceCompany,
            LoginMethod.SWEDISH_MOBILE_BANKID_OTHER_DEVICE
          );
        } else if (
          company.loginMethods.includes(
            LoginMethod.SWEDISH_MOBILE_BANKID_ANY_DEVICE
          )
        ) {
          startBankId(
            company.insuranceCompany,
            LoginMethod.SWEDISH_MOBILE_BANKID_ANY_DEVICE
          );
        }
      }}
    />
  );
};

const BankIdAnyButton = ({
  company,
  startBankId,
}: {
  company: CompanyInfo;
  startBankId: StartBankId;
}) => {
  const intl = useIntl();
  const query = new URLSearchParams(useLocation().search);
  const method = query.get("method");

  return (
    <Button
      className="actions"
      icon="BankId"
      block
      type="button"
      variant="primary"
      label={intl.formatMessage({
        id: "sweden.transfer-pension.insurance-signing-list.signing.button",
      })}
      onClick={() => {
        if (method === "SAME") {
          if (
            company.loginMethods.includes(
              LoginMethod.SWEDISH_MOBILE_BANKID_SAME_DEVICE
            )
          ) {
            startBankId(
              company.insuranceCompany,
              LoginMethod.SWEDISH_MOBILE_BANKID_SAME_DEVICE
            );
          } else if (
            company.loginMethods.includes(
              LoginMethod.SWEDISH_MOBILE_BANKID_ANY_DEVICE
            )
          ) {
            startBankId(
              company.insuranceCompany,
              LoginMethod.SWEDISH_MOBILE_BANKID_ANY_DEVICE
            );
          } else if (
            company.loginMethods.includes(
              LoginMethod.SWEDISH_MOBILE_BANKID_OTHER_DEVICE
            )
          ) {
            startBankId(
              company.insuranceCompany,
              LoginMethod.SWEDISH_MOBILE_BANKID_OTHER_DEVICE
            );
          }
        } else if (method === "OTHER") {
          if (
            company.loginMethods.includes(
              LoginMethod.SWEDISH_MOBILE_BANKID_OTHER_DEVICE
            )
          ) {
            startBankId(
              company.insuranceCompany,
              LoginMethod.SWEDISH_MOBILE_BANKID_OTHER_DEVICE
            );
          } else if (
            company.loginMethods.includes(
              LoginMethod.SWEDISH_MOBILE_BANKID_ANY_DEVICE
            )
          ) {
            startBankId(
              company.insuranceCompany,
              LoginMethod.SWEDISH_MOBILE_BANKID_ANY_DEVICE
            );
          } else if (
            company.loginMethods.includes(
              LoginMethod.SWEDISH_MOBILE_BANKID_SAME_DEVICE
            )
          ) {
            startBankId(
              company.insuranceCompany,
              LoginMethod.SWEDISH_MOBILE_BANKID_SAME_DEVICE
            );
          }
        }
      }}
    />
  );
};

const RetryButton = ({
  company,
  startBankId,
}: {
  company: CompanyInfo;
  startBankId: StartBankId;
}) => {
  const query = new URLSearchParams(useLocation().search);
  const method = query.get("method");

  return (
    <CircleButton
      icon="Retry"
      type="button"
      variant="outlined"
      onClick={() => {
        if (method === "SAME") {
          if (
            company.loginMethods.includes(
              LoginMethod.SWEDISH_MOBILE_BANKID_SAME_DEVICE
            )
          ) {
            startBankId(
              company.insuranceCompany,
              LoginMethod.SWEDISH_MOBILE_BANKID_SAME_DEVICE
            );
          } else if (
            company.loginMethods.includes(
              LoginMethod.SWEDISH_MOBILE_BANKID_ANY_DEVICE
            )
          ) {
            startBankId(
              company.insuranceCompany,
              LoginMethod.SWEDISH_MOBILE_BANKID_ANY_DEVICE
            );
          } else if (
            company.loginMethods.includes(
              LoginMethod.SWEDISH_MOBILE_BANKID_OTHER_DEVICE
            )
          ) {
            startBankId(
              company.insuranceCompany,
              LoginMethod.SWEDISH_MOBILE_BANKID_OTHER_DEVICE
            );
          }
        } else if (method === "OTHER") {
          if (
            company.loginMethods.includes(
              LoginMethod.SWEDISH_MOBILE_BANKID_OTHER_DEVICE
            )
          ) {
            startBankId(
              company.insuranceCompany,
              LoginMethod.SWEDISH_MOBILE_BANKID_OTHER_DEVICE
            );
          } else if (
            company.loginMethods.includes(
              LoginMethod.SWEDISH_MOBILE_BANKID_ANY_DEVICE
            )
          ) {
            startBankId(
              company.insuranceCompany,
              LoginMethod.SWEDISH_MOBILE_BANKID_ANY_DEVICE
            );
          } else if (
            company.loginMethods.includes(
              LoginMethod.SWEDISH_MOBILE_BANKID_SAME_DEVICE
            )
          ) {
            startBankId(
              company.insuranceCompany,
              LoginMethod.SWEDISH_MOBILE_BANKID_SAME_DEVICE
            );
          }
        }
      }}
    />
  );
};

const CollectPensionSigningStatus: React.FC<{
  qrCode?: string;
  animatedQrData?: string;
  autostartToken?: string;
  status: string;
}> = ({ qrCode, animatedQrData, autostartToken, status }) => {
  const intl = useIntl();
  const query = new URLSearchParams(useLocation().search);
  const method = query.get("method");
  const history = useHistory();

  const setQueryParameter = (value: "SAME" | "OTHER") => {
    const currentPath = window.location.pathname;
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set("method", value);
    history.replace(`${currentPath}?${searchParams.toString()}`);
  };

  const noExtraInformation = !qrCode && !autostartToken;
  const deprecatedTinLogin = status === "LOGIN" && noExtraInformation;

  if (status === "RUNNING" && noExtraInformation) {
    return (
      <section className="container">
        <Spinner />
        <Typography type="body">
          <TranslatedText id="sweden.transfer-pension.insurance-signing-list.wait-to-start-bank-id" />
        </Typography>
      </section>
    );
  }

  if (deprecatedTinLogin) {
    return (
      <section className="container">
        <Spinner />
        <Typography type="body">
          <TranslatedText id="sweden.transfer-pension.insurance-signing-list.open-bank-id-on-your-device" />
        </Typography>
      </section>
    );
  }

  return method === "SAME" ? (
    <section className="container">
      <Spinner />
      <Button
        block
        type="button"
        variant="primary"
        label={intl.formatMessage({
          id: "sweden.transfer-pension.insurance-signing.start-bank-id.button",
        })}
        onClick={() => autostartToken && openBankidApp(autostartToken)}
      />
      <Button
        block
        type="button"
        variant="outlined"
        label={intl.formatMessage({
          id: "sweden.transfer-pension.insurance-signing-list.show-qr-code.button",
        })}
        onClick={() => setQueryParameter("OTHER")}
      />
    </section>
  ) : (
    <section className="container">
      {(qrCode || animatedQrData) && (
        <section className="qr-container">
          <div className="qr-wrapper">
            {animatedQrData ? (
              <QRCodeCanvas
                size={172}
                includeMargin={true}
                value={animatedQrData || ""}
              />
            ) : (
              <img alt="QR code" src={`data:image/png;base64,${qrCode}`} />
            )}
          </div>
          <ol className="qr-instructions">
            <li>
              <TranslatedText id="sweden.transfer-pension.insurance-signing-list.qrInfo1" />
            </li>
            <li>
              <TranslatedText id="sweden.transfer-pension.insurance-signing-list.qrInfo2" />
            </li>
            <li>
              <TranslatedText id="sweden.transfer-pension.insurance-signing-list.qrInfo3" />
            </li>
          </ol>
        </section>
      )}
      <Button
        block
        type="button"
        variant="outlined"
        label={intl.formatMessage({
          id: "sweden.transfer-pension.insurance-signing-list.start-bank-id.same.button",
        })}
        onClick={() => setQueryParameter("SAME")}
      />
    </section>
  );
};
