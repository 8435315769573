import { FunctionComponent, useRef, useState } from "react";
import { Move, useTransfer } from "../../TransferContext";
import {
  Button,
  Form,
  LysaFormRef,
  MinLengthValidator,
  MinValidator,
  MoneyInput,
  OrgValidator,
  RadioGroupCard,
  RequiredValidator,
  Select,
  Snackbar,
  SNACKBAR_TYPES,
  TextInput,
  Typography,
} from "@lysaab/ui-2";
import { useIntl } from "react-intl";
import { AccountType } from "../../../../../../data/dataAccounts";
import { instituteAlternatives } from "../../utils/institutePrettyNames";
import { TranslatedText } from "../../../../../../components/TranslatedText";

import "./ManualModalContent.scss";

interface Props {
  closeModal: () => void;
  incoming?: Move;
  type?: "add" | "edit";
}

export const ManualModalContent: FunctionComponent<Props> = ({
  closeModal,
  incoming,
  type,
}) => {
  const formRef = useRef<LysaFormRef>();
  const intl = useIntl();
  const [transfer, setTransfer] = useTransfer();
  const [move, setMove] = useState<Move>({
    type: incoming?.type || undefined,
    institute: incoming?.institute || undefined,
    insuranceNumber: incoming?.insuranceNumber || undefined,
    employer: incoming?.employer || undefined,
    employerTin: incoming?.employerTin || undefined,
    checked: incoming?.checked || true,
    id: incoming?.id || crypto.randomUUID(),
  });

  return (
    <Form
      lysaFormRef={formRef}
      onSubmit={(event: React.SyntheticEvent<HTMLFormElement, SubmitEvent>) => {
        event.preventDefault();
        if (!formRef.current?.isValid) {
          return;
        }
        if (type === "add") {
          setTransfer({
            moves: [...transfer.moves, move],
          });
        }
        if (type === "edit") {
          const nextMoves = transfer.moves.filter(
            (move) => move.id !== incoming?.id
          );
          setTransfer({
            moves: [...nextMoves, move],
          });
        }
        closeModal();
        event.stopPropagation();
      }}
    >
      <RadioGroupCard
        alternatives={[
          {
            header: intl.formatMessage({
              id: "sweden.transfer-pension.pension-type.tjp.header",
            }),
            description: intl.formatMessage({
              id: "sweden.transfer-pension.pension-type.tjp.description",
            }),
            value: AccountType.LYSA_TJP,
          },
          {
            header: intl.formatMessage({
              id: "sweden.transfer-pension.pension-type.ppf.header",
            }),
            description: intl.formatMessage({
              id: "sweden.transfer-pension.pension-type.ppf.description",
            }),
            value: AccountType.LYSA_PPF,
          },
        ]}
        legend={
          <label className="radio-group-card-label">
            <TranslatedText id="sweden.transfer-pension.pension-type.label" />
          </label>
        }
        onChange={(newType) => {
          setMove({ ...move, type: newType });
        }}
        validators={[
          new RequiredValidator(
            intl.formatMessage({
              id: "sweden.transfer-pension.pension-type.required",
            })
          ),
        ]}
        value={move.type}
      />
      <Select
        label={intl.formatMessage({
          id: "sweden.transfer-pension.insurance-info.institute-select.label",
        })}
        placeholder={intl.formatMessage({
          id: "sweden.transfer-pension.insurance-info.institute-select.placeholder",
        })}
        validators={[
          new RequiredValidator(
            intl.formatMessage({
              id: "sweden.transfer-pension.insurance-info.institute-select.error",
            })
          ),
        ]}
        alternatives={instituteAlternatives}
        value={instituteAlternatives.find(
          (alternative) => alternative.value === move.institute
        )}
        onChange={(newInstitute) => {
          setMove({ ...move, institute: newInstitute.value });
        }}
      />
      <section className="transfer-pension-manual-input-group">
        <MoneyInput
          currency="SEK"
          label={intl.formatMessage({
            id: "sweden.transfer-pension.insurance-info.estimated-worth.label",
          })}
          placeholder={intl.formatMessage({
            id: "sweden.transfer-pension.insurance-info.estimated-worth.placeholder",
          })}
          value={move.currentWorth?.toString() ?? ""}
          onChange={(newEstimatedWorth) => {
            setMove({ ...move, currentWorth: Number(newEstimatedWorth) });
          }}
          validators={[
            new MinValidator(
              1,
              intl.formatMessage({
                id: "sweden.transfer-pension.insurance-info.estimated-worth.min",
              })
            ),
          ]}
        />
        <Typography type="body-small" className="input-info">
          <TranslatedText id="sweden.transfer-pension.insurance-info.estimated-worth.info" />
        </Typography>
      </section>
      <section className="transfer-pension-manual-input-group">
        <TextInput
          label={intl.formatMessage({
            id: "sweden.transfer-pension.insurance-info.insurance-number-input.label",
          })}
          placeholder={intl.formatMessage({
            id: "sweden.transfer-pension.insurance-info.insurance-number-input.placeholder",
          })}
          validators={[
            new RequiredValidator(
              intl.formatMessage({
                id: "sweden.transfer-pension.insurance-info.insurance-number-input.required.error",
              })
            ),
            new MinLengthValidator(
              5,
              intl.formatMessage({
                id: "sweden.transfer-pension.insurance-info.insurance-number-input.length.error",
              })
            ),
          ]}
          value={move.insuranceNumber ?? ""}
          onChange={(newInsuranceNumber) => {
            setMove({ ...move, insuranceNumber: newInsuranceNumber });
          }}
          showSuccessState
        />
        <Typography type="body-small" className="input-info">
          <TranslatedText id="sweden.transfer-pension.insurance-info.insurance-number-input.info" />
        </Typography>
      </section>
      {(move.type === undefined || move.type === AccountType.LYSA_TJP) && (
        <section className="transfer-pension-manual-input-group">
          <TextInput
            label={intl.formatMessage({
              id: "sweden.transfer-pension.employer-info.org-number-input.label",
            })}
            placeholder={intl.formatMessage({
              id: "sweden.transfer-pension.employer-info.org-number-input.placeholder",
            })}
            validators={[
              new RequiredValidator(
                intl.formatMessage({
                  id: "sweden.transfer-pension.employer-info.org-number-input.required",
                })
              ),
              new OrgValidator(
                intl.formatMessage({
                  id: "sweden.transfer-pension.employer-info.org-number-input.org",
                })
              ),
            ]}
            value={move.employerTin ?? ""}
            onChange={(newEmployerTin) => {
              setMove({ ...move, employerTin: newEmployerTin });
            }}
          />
          <Typography type="body-small" className="input-info">
            <TranslatedText id="sweden.transfer-pension.employer-info.org-number-input.info" />
          </Typography>
        </section>
      )}
      <Snackbar type={SNACKBAR_TYPES.INFO} icon textAlign="left">
        <TranslatedText
          id="sweden.transfer-pension.insurance-info.snackbar"
          values={{
            link: (text) => {
              return (
                <a
                  className="snackbar-link"
                  target="_blank"
                  href="https://www.minpension.se/"
                  rel="noreferrer"
                >
                  <strong>{text}</strong>
                </a>
              );
            },
          }}
        />
      </Snackbar>
      <Button
        block
        type="submit"
        variant="primary"
        label={intl.formatMessage({
          id: "sweden.transfer-pension.insurance-info.add",
          defaultMessage: "Fortsätt",
        })}
      />
    </Form>
  );
};
